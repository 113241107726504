/*

 Custom styles for Cargolift organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/
.login--form-logo {
    padding: 20px !important;
    &-image {
        width: 235px !important;
    }
}

.login--logo,
.header-provider--logo {
    max-width: 150px !important;
}

.header--logo {
    max-width: 135px !important;
}

.login--footer-logo {
    height: 10%;
    display: flex !important;
    &-image {
        width: 200px;
    }
}