/*

 Custom colors for Cargolift organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #144c96;
$organization_primary_focus: #0e3569;
$organization_primary_disabled: #005b9d4d;

$organization_program_default: #144c96;

$organization_secondary: #005b9d;
$organization_secondary_focus: #0268e4;
$organization_secondary_transparent: #0166e2af;
$organization_secundary_border: #6890c0;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #74bcff;

$organization_secundary_button: #0066e2;
$organization_secundary_button_focus: #0d64ce;

